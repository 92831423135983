<template>
  <div id="login-page">
    <div class="wrapper fadeInDown">
      <div id="formContent">
        <div class="fadeIn first circular--portrait">
          <img :src="logo" id="icon" alt="Company Logo" />
        </div>
        <!-- Login Form -->

        <form
          @submit.prevent="login"
          @keydown="form.errors.clear($event.target.name)"
        >
          <span class="error-message"
            >{{ form.errors.message }} <br />
            <strong v-if="loginAttempts <= 3 && loginAttempts"
              >You Have: {{ 3 - loginAttempts }} More Attempts Left</strong
            >
          </span>
          <div class="login-input">
            <input
              type="text"
              id="login"
              class="fadeIn second"
              :class="form.errors.has('username') ? 'red' : ''"
              autocomplete="off"
              name="username"
              v-model="form.username"
              placeholder="Username"
            />
          </div>
          <div class="login-input">
            <input
              :type="unlock === true ? 'text' : 'password'"
              id="password"
              class="fadeIn third"
              :class="form.errors.has('password') ? 'red' : ''"
              autocomplete="off"
              name="password"
              v-model="form.password"
              placeholder="Password"
            />
            <v-icon v-if="unlock === false" @click="unlock = true">lock</v-icon>
            <v-icon v-else @click="unlock = false">lock_open</v-icon>
          </div>
          <!-- {{ form.password.length }} -->
          <input
            :disabled="
              form.password.length < 8 ||
                form.username.length < 3 ||
                loginAttempts >= 3
            "
            type="submit"
            class="fadeIn fourth login_btn"
            value="Log In"
          />
        </form>
        <!-- Remind Passowrd -->
        <div id="formFooter">
          <a class="underlineHover" href="#">Forgot Password?</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "@/library/Form";
import cookie from "js-cookie";
import { mapActions } from "vuex";
import { getPropertyFromArrayObject } from "../../../library/helpers";
import ls from "./../../../library/Storage";

export default {
  data: () => ({
    requestingPermission: false,
    roleSelectDialog: false,
    unlock: false,
    roleAvatar: [
      {
        name: "administrator",
        avatar: "https://image.flaticon.com/icons/svg/265/265675.svg",
      },

      {
        name: "teacher",
        avatar: "https://image.flaticon.com/icons/svg/1089/1089129.svg",
      },
      {
        name: "accountant",
        avatar: "https://image.flaticon.com/icons/svg/1728/1728858.svg",
      },
      {
        name: "guardian",
        avatar: "https://image.flaticon.com/icons/svg/374/374971.svg",
      },
    ],
    roles: [],
    items: [
      { header: "Sign in as" },
      {
        avatar: "https://image.flaticon.com/icons/svg/265/265675.svg",
        title: "Administrator",
        subtitle:
          "<span class='text--primary'>Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
      },
      { divider: true, inset: true },
      {
        avatar: "https://image.flaticon.com/icons/svg/1089/1089129.svg",
        title:
          'Teacher <small class="grey--text text--lighten-1">12 Permissions</small>',
        subtitle:
          "<span class='text--primary'>Last Logged In</span> &mdash; 12 Hours Ago",
      },
      { divider: true, inset: true },
      {
        avatar: "https://image.flaticon.com/icons/svg/1728/1728858.svg",
        title: "Accountant",
        subtitle:
          "<span class='text--primary'>Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?",
      },
    ],
    form: new Form(
      {
        username: "",
        password: "",
      },
      "/auth/login"
    ),
    loginAttempts: null,
  }),
  mounted() {},
  computed: {
    logo() {
      let companyLogo =
        cookie.get("_cl") ||
        "https://eacademy.sgp1.cdn.digitaloceanspaces.com/beta/school/rmUpdLgOX9vcimXTahH65yyJgJck8sOw4mpZIs3G.png";
      return location.host === "lekhangkan.com" ||
        location.host === "service.lekhangkan.com" ||
        location.host === "lekhangkan.sahakaryatech.com"
        ? "https://eacademy.sgp1.digitaloceanspaces.com/lekhangkan/logo_lekh.png"
        : companyLogo;
    },
  },
  methods: {
    ...mapActions(["setUser"]),
    getProperty: getPropertyFromArrayObject,

    fetchCommunicatorToken() {
      this.$rest.get("/auth/communicator/token").then((res) => {
        if (res.data.communicate_token) {
          ls.set("_comm_tk", res.data.communicate_token);
        }
      });
    },

    login() {
      this.$rest
        .post(this.form.endpoint, this.form.data())
        .then(({ data }) => {
          this.$auth.set(data);
          this.setUser(this.$auth.user());
          this.fetchCommunicatorToken();
          this.$auth.setRole("accountant");
          this.$router.replace({ name: "home" });
        })
        .catch((err) => {
          const { response } = err;
          if (response) {
            if (response.data.total_attempts !== 0) {
              this.loginAttempts = response.data.total_attempts;
            }
            this.form.errors.record(
              response.data.message,
              response.data.errors
            );
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
#login-page {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: #9053c7;
  background: -webkit-linear-gradient(-135deg, #c850c0, #4158d0);
  background: -o-linear-gradient(-135deg, #c850c0, #4158d0);
  background: -moz-linear-gradient(-135deg, #c850c0, #4158d0);
  background: linear-gradient(-135deg, #c850c0, #4158d0);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type="text"],
input[type="password"] {
  background-color: #f6f6f6;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 95%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

input.red {
  color: red;
  &:focus {
    border-bottom: 2px solid red;
  }
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 260px;
  margin-top: 25px;
}

* {
  box-sizing: border-box;
}

input[type="submit"]:disabled {
  cursor: not-allowed;
  background-color: #ccc;
  box-shadow: none;
}

.error-message {
  color: #ef4545;
  padding: 0 15px;
}

.login-input {
  width: 85%;
  margin: auto;
  position: relative;
  .material-icons {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }
}

.login_btn {
  background-color: #379eaf !important;
}
</style>
